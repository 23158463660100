.floatingedges {
  flex-direction: column;
  display: flex;
  position: absolute;
  flex-grow: 1;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

body {
  background-color: rgb(17, 17, 17) !important;
}

.footer {
  background-color: rgb(5, 5, 5) !important;
}

.floatingedges .react-flow__handle {
  opacity: 0;
}

article {
  z-index: 1;
  max-width: 630px;
  margin-left: -70px !important;
}

.container {
  width: 500px;
}

.header {
  position: absolute !important;
  width: 100% !important;
  background-color: #00000030;
}

.react-flow {
  --bg-color: rgb(17, 17, 17);
  --text-color: rgb(243, 244, 246);
  --node-border-radius: 10px;
  --node-box-shadow: 10px 0 15px rgba(42, 138, 246, 0.3), -10px 0 15px rgba(233, 42, 103, 0.3);
  background-color: var(--bg-color);
  color: var(--text-color);
}

.react-flow__node-turbo {
  border-radius: var(--node-border-radius);
  display: flex;
  height: 70px;
  min-width: 150px;
  font-family: 'Fira Mono', Monospace;
  font-weight: 500;
  letter-spacing: -0.2px;
  box-shadow: var(--node-box-shadow);
}

.react-flow__node-turbo .wrapper {
  overflow: hidden;
  display: flex;
  padding: 2px;
  position: relative;
  border-radius: var(--node-border-radius);
  flex-grow: 1;
}

.gradient:before {
  content: '';
  position: absolute;
  padding-bottom: calc(100% * 1.41421356237);
  width: calc(100% * 1.41421356237);
  background: conic-gradient(from -160deg at 50% 50%,
      #e92a67 0deg,
      #a853ba 120deg,
      #2a8af6 240deg,
      #e92a67 360deg);
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 100%;
}

.react-flow__node-turbo .wrapper.gradient:before {
  content: '';
  background: conic-gradient(from -160deg at 50% 50%,
      #e92a67 0deg,
      #a853ba 120deg,
      #2a8af6 240deg,
      rgba(42, 138, 246, 0) 360deg);
  animation: spinner 4s linear infinite;
  transform: translate(-50%, -50%) rotate(0deg);
  z-index: -1;
}

@keyframes spinner {
  100% {
    transform: translate(-50%, -50%) rotate(-360deg);
  }
}

.react-flow__node-turbo .inner {
  background: var(--bg-color);
  padding: 16px 20px;
  border-radius: var(--node-border-radius);
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
  position: relative;
}

.react-flow__node-turbo .icon {
  margin-right: 8px;
}

.react-flow__node-turbo .body {
  display: flex;
}

.react-flow__node-turbo .title {
  font-size: 16px;
  margin-bottom: 2px;
  line-height: 1;
}

.react-flow__node-turbo .full-title {
  font-size: 24px;
  margin-bottom: 2px;
  line-height: 1;
}

@keyframes glow-and-fade {

  0%,
  100% {
    text-shadow: 0 0 8px rgba(255, 255, 255, 0.5);
    opacity: 0;
  }

  50% {
    text-shadow: 0 0 20px rgba(255, 255, 255, 1);
    opacity: 1;
  }
}

.react-flow__node-turbo .subline {
  font-size: 12px;
  color: #fff;
  text-shadow: 0 0 8px rgba(255, 255, 255, 0.5);
  animation: glow-and-fade 3s ease-in-out infinite;
}

.article2 {
  min-height: 900px !important;
}


.react-flow__node-turbo .cloud {
  border-radius: 100%;
  width: 30px;
  height: 30px;
  right: 0;
  position: absolute;
  top: 0;
  transform: translate(50%, -50%);
  display: flex;
  transform-origin: center center;
  padding: 2px;
  overflow: hidden;
  box-shadow: var(--node-box-shadow);
  z-index: 1;
}

.react-flow__node-turbo .cloud div {
  background-color: var(--bg-color);
  flex-grow: 1;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.react-flow__handle {
  opacity: 0;
}

.react-flow__handle.source {
  right: -10px;
}

.react-flow__handle.target {
  left: -10px;
}

.react-flow__node:focus {
  outline: none;
}

.react-flow__edge .react-flow__edge-path {
  stroke: #a853ba;
  stroke-width: 2;
  stroke-opacity: 0.75;
}

.react-flow__controls button {
  background-color: var(--bg-color);
  color: var(--text-color);
  border: 1px solid #95679e;
  border-bottom: none;
}

.react-flow__controls button:hover {
  background-color: rgb(37, 37, 37);
}

.react-flow__controls button:first-child {
  border-radius: 5px 5px 0 0;
}

.react-flow__controls button:last-child {
  border-bottom: 1px solid #95679e;
  border-radius: 0 0 5px 5px;
}

.react-flow__controls button path {
  fill: var(--text-color);
}

.react-flow__attribution {
  background: rgba(200, 200, 200, 0.2);
}

.react-flow__attribution a {
  color: #95679e;
}